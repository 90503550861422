import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import SwaggerUI from "swagger-ui-react";
import decode from "jwt-decode";
import { isBefore } from 'date-fns';
import "swagger-ui-react/swagger-ui.css";

function App() {
  const [swag, setSwag] = useState();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useLocalStorage('lvmpd-backend-token');

  const getToken = useCallback(async () => {
    // Check if current token is valid
    if (token) {
      const { exp } = decode(token);
      const expirationDate = new Date(exp * 1000);
      if (isBefore(new Date(), expirationDate)) {
        return token;
      }
    }
    // If it is not then we will fetch a token
    const myHeaders = new Headers();
    myHeaders.append("content-type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");
    urlencoded.append("client_id", process.env.REACT_APP_COGNITO_CLIENT_ID);
    urlencoded.append("client_secret", process.env.REACT_APP_COGNITO_CLIENT_SECRET);
    urlencoded.append("scope", process.env.REACT_APP_COGNITO_SCOPE);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    const { access_token } = await fetch(process.env.REACT_APP_COGNITO_TOKEN_URL, requestOptions)
      .then(response => response.json())
      .catch(error => {
        console.error('error', error);
        return {};
      });

    setToken(access_token);
    return access_token;
  }, [token, setToken]);

  const requestInterceptor = useCallback(async (req) => {
    const token = await getToken();
    const url = req.url.replace(window.location.origin, process.env.REACT_APP_SWAG_URL);
    return {
      ...req,
      headers: {
        ...req.headers,
        Authorization: `Bearer ${token}`,
      },
      url,
    }
  }, [getToken]);

  useEffect(() => {
    const getSwagDoc = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_SWAG_URL}/docs`);
        if (!res.ok) {
          throw new Error('Failed to fetch openAPI doc');
        }
        const data = await res.json();
        setSwag(data);
      } catch(error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getSwagDoc();
  }, []);

  if (loading) {
    return (
      <div style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '15px',
        fontWeight: 'bold',
      }}>
        Loading...
      </div>
    )
  }

  return <SwaggerUI spec={swag} requestInterceptor={requestInterceptor} />;
}

export default App;
